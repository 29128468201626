export const Window = ({
  id,
  title,
  children,
  onClose,
  width,
  height,
  ...props
}) => (
  <div
    className="window window-default"
    data-id={id}
    data-window={true}
    {...props}
  >
    <div className="window-inside" style={{ width, height }}>
      <WindowNav
        title={title}
        onMinimize={() => console.log("Minimize")}
        onMaximize={() => console.log("Maximize")}
        onClose={onClose}
      />
      <div className="window-content">
        <div className="list-container above-footer">{children}</div>
      </div>
    </div>
  </div>
);

const WindowNav = ({ title, onMinimize, onMaximize, onClose }) => (
  <nav className="window-nav">
    <h3>
      <span>{title}</span>
    </h3>
    <div className="buttons">
      {/* <label className="minimize" onClick={onMinimize}></label> */}
      {/* <label className="maximize" onClick={onMaximize}></label> */}
      <label className="close" onClick={onClose}></label>
    </div>
  </nav>
);
