import React, { useState, useEffect, useRef } from "react";
import styles from "./Ticker.module.scss";

const ScrollTicker = ({
  children,
  reverse = false,
  speed = 10,
  offset = 0,
  segmentCount = 3,
  className = "",
}) => {
  const tickerClasses = [styles.Ticker, reverse && styles.reverse, className]
    .filter(Boolean)
    .join(" ");

  return (
    <div
      className={tickerClasses}
      style={{ transform: `translateX(-${offset}%)` }}
    >
      {[...Array(segmentCount)].map((_, index) => (
        <span
          key={index}
          className={styles.segment}
          style={{ animationDuration: `${speed}s` }}
        >
          {children}
        </span>
      ))}
    </div>
  );
};

export default ScrollTicker;
