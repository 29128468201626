// imageWindow.module.scss

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, 0) scale(1);
  }
  50% {
    transform: translate(-50%, 0) scale(1.2);
  }
  100% {
    transform: translate(-50%, 0) scale(1);
  }
}

.ticketButton {
  position: absolute;
  bottom: 15%;
  left: 50%;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  color: white;
  background-color: #2563eb;
  animation: pulsate 2s infinite ease-in-out;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3d5544;
  }
}
