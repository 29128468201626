import React, { useState, useRef, useEffect } from "react";
import clickSound from "../../assets/sounds/switch-on.mp3";
import imageBg from "../../assets/tryb-sporth/player-bg@2x.png";
import recordImg from "../../assets/tryb-sporth/record@2x.png";
import styles from "./MusicPlaylist.module.scss";
import useSound from "use-sound";
import useLetterTransition from "../../hooks/useLetterTransition";
import Pause from "../../assets/tryb-sporth/icons/Pause.svg";
import Play from "../../assets/tryb-sporth/icons/Play.svg";
import Prev from "../../assets/tryb-sporth/icons/Prev.svg";
import Next from "../../assets/tryb-sporth/icons/Next.svg";

const Icon = ({ children }) => <div className={styles.icon}>{children}</div>;

const MusicPlaylist = () => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const recordRef = useRef(null);
  const [playClick] = useSound(clickSound);
  const rotationRef = useRef(0);
  const animationFrameRef = useRef(null);

  const numbers = "①②③④⑤⑥⑦⑧⑨⑩⑪⑫".split("");

  const playlist = [
    { url: "01.mp3", title: "NIE DLA KAMER (INTRO)" },
    { url: "02.mp3", title: "STYL" },
    { url: "03.mp3", title: "3W1" },
    { url: "04.mp3", title: "GÓRA DÓŁ" },
    { url: "05.mp3", title: "OSTATNI RAZ" },
    { url: "06.mp3", title: "LOCATION" },
    { url: "07.mp3", title: "LOTH FT. PLANBE" },
    { url: "08.mp3", title: "TRYB SPORTH" },
    { url: "09.mp3", title: "SOMY RAPERY FT. CZERNY" },
    { url: "10.mp3", title: "X" },
    { url: "11.mp3", title: "SEKWENCJA FIBONACCIEGO" },
    { url: "12.mp3", title: "MEMORIES V.2" },
  ];

  const resumeAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }

    if (audioContextRef.current.state === "suspended") {
      audioContextRef.current.resume();
    }
  };

  const playTrack = (index, click = true) => {
    if (click) playClick();
    resumeAudioContext();
    setCurrentTrackIndex(index);
    audioRef.current.src = `/tryb-sporth-snippets/${playlist[index].url}`;
    audioRef.current.load();
    audioRef.current
      .play()
      .then(() => setIsPlaying(true))
      .catch((error) => console.error("Error playing audio:", error));
  };

  const togglePlay = () => {
    if (!isPlaying) {
      playTrack(currentTrackIndex);
    } else {
      playClick();
      resumeAudioContext();
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => console.error("Error playing audio:", error));
      }
    }
  };

  const nextTrack = (click = true) => {
    playClick();
    const nextIndex = (currentTrackIndex + 1) % playlist.length;
    playTrack(nextIndex, click);
  };

  const prevTrack = () => {
    playClick();
    const prevIndex =
      (currentTrackIndex - 1 + playlist.length) % playlist.length;
    playTrack(prevIndex);
  };

  const rotateRecord = () => {
    if (recordRef.current) {
      rotationRef.current = (rotationRef.current + 2 + currentTrackIndex) % 360;
      recordRef.current.style.transform = `rotate(${rotationRef.current}deg)`;
    }
    animationFrameRef.current = requestAnimationFrame(rotateRecord);
  };

  useEffect(() => {
    if (isPlaying) {
      animationFrameRef.current = requestAnimationFrame(rotateRecord);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
    }

    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [isPlaying, currentTrackIndex]);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleEnded = () => nextTrack(false);

    const handleProgress = (e) => {
      if (audioElement.buffered.length > 0) {
        const bufferedEnd = audioElement.buffered.end(
          audioElement.buffered.length - 1
        );
      }
    };

    audioElement.addEventListener("ended", handleEnded);
    audioElement.addEventListener("progress", handleProgress);

    return () => {
      audioElement.removeEventListener("ended", handleEnded);
      audioElement.removeEventListener("progress", handleProgress);
    };
  }, [currentTrackIndex]);

  const displayedTitle = `${numbers[currentTrackIndex]} ${useLetterTransition(
    playlist[currentTrackIndex].title
  )}`;

  return (
    <div className={styles.container}>
      <audio ref={audioRef} className={styles.audio} />
      <div className={styles.playlistWrapper}>
        <img className={styles.bg} src={imageBg} alt="Player bg" />
        <div className={styles.record}>
          <img src={recordImg} ref={recordRef} alt="Record" />
        </div>
        <div className={styles.playlistContainer}>
          <div className={styles.group}>
            <svg
              className={styles.title}
              viewBox="0 0 600 20"
              preserveAspectRatio="xMinYMid meet"
              xmlns="http://www.w3.org/2000/svg"
            >
              <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#3d5544"
              >
                {displayedTitle}
              </text>
            </svg>
            <div className={styles.controlContainer}>
              <button onClick={prevTrack} className={styles.controlButton}>
                <Icon>
                  <Prev />
                </Icon>
              </button>
              <button
                onClick={togglePlay}
                className={[
                  styles.controlButton,
                  !isPlaying && styles.flash,
                ].join(" ")}
              >
                {isPlaying ? (
                  <Icon>
                    <Pause />
                  </Icon>
                ) : (
                  <Icon>
                    <Play />
                  </Icon>
                )}
              </button>
              <button onClick={nextTrack} className={styles.controlButton}>
                <Icon>
                  <Next />
                </Icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicPlaylist;
