import React, { useState, useEffect } from "react";

const MenuItem = ({ className, children }) => (
  <li>
    <a className={className}>
      <span>{children}</span>
    </a>
  </li>
);

const SubMenu = ({ children }) => (
  <menu className="menu sub-menu">{children}</menu>
);

const LabelItem = ({ className, htmlFor, children, onClick }) => (
  <li onClick={onClick}>
    <label className={className} htmlFor={htmlFor}>
      <span>{children}</span>
    </label>
  </li>
);

const ProgramsMenu = () => (
  <MenuItem className="label-folder-programs label-nested">
    Programs
    <SubMenu>
      <MenuItem className="label-folder-programs-small label-nested">
        Accessories
        <SubMenu>
          <LabelItem className="label-notepad-small" htmlFor="notepad">
            Notepad
          </LabelItem>
        </SubMenu>
      </MenuItem>
      <MenuItem className="label-folder-programs-small label-nested">
        Start Up
        <SubMenu>
          <MenuItem className="label-empty">(Empty)</MenuItem>
        </SubMenu>
      </MenuItem>
      <LabelItem className="label-folder-explorer-small" htmlFor="explorer">
        Windows Explorer
      </LabelItem>
    </SubMenu>
  </MenuItem>
);

const DocumentsMenu = () => (
  <MenuItem className="label-folder-documents label-nested">
    Documents
    <SubMenu>
      <MenuItem className="label-empty">(Empty)</MenuItem>
    </SubMenu>
  </MenuItem>
);

const SettingsMenu = () => (
  <MenuItem className="label-settings label-nested">
    Settings
    <SubMenu>
      <LabelItem className="label-control-panel-small" htmlFor="control-panel">
        Control Panel
      </LabelItem>
      <LabelItem className="label-folder-printers-small" htmlFor="printers">
        Printers
      </LabelItem>
      <LabelItem className="label-taskbar" htmlFor="taskbar">
        Taskbar...
      </LabelItem>
    </SubMenu>
  </MenuItem>
);

const FindMenu = () => (
  <MenuItem className="label-find label-nested">
    Find
    <SubMenu>
      <LabelItem className="label-find-small label-disabled">
        Files or Folders
      </LabelItem>
    </SubMenu>
  </MenuItem>
);

const MainMenu = ({ onShutDown, onBooking }) => (
  <menu className="menu main-menu underline">
    {/* <ProgramsMenu /> */}
    {/* <DocumentsMenu /> */}
    {/* <SettingsMenu /> */}
    {/* <FindMenu /> */}
    {/* <LabelItem className="label-help">Help</LabelItem> */}
    <LabelItem className="label-run" onClick={onBooking}>
      Bookuj nas...
    </LabelItem>
    <li>
      <label
        className="label-shut-down"
        htmlFor="error-blue-screen"
        onClick={onShutDown}
      >
        <span>
          <span className="line">R</span>
          <span>estart</span>
        </span>
      </label>
    </li>
  </menu>
);

const TabItem = ({ className, labelClass, htmlFor, children }) => (
  <li className={`tab ${className}`} data-tab>
    <span className="activate"></span>
    <label className={labelClass} htmlFor={htmlFor}>
      <span>{children}</span>
    </label>
  </li>
);

const Tabs = () => (
  <menu className="tabs">
    {/* <TabItem
      className="tab-printers"
      labelClass="label-folder-printers-small"
      htmlFor="printers-minimize"
    >
      Printers
    </TabItem>
    <TabItem
      className="tab-control-panel"
      labelClass="label-control-panel-small"
      htmlFor="control-panel-minimize"
    >
      Control Panel
    </TabItem> */}
    <TabItem
      className="tab-computer"
      labelClass="label-disk-small"
      htmlFor="computer-minimize"
    >
      Ostatni raz
    </TabItem>
    {/* <TabItem
      className="tab-notepad"
      labelClass="label-document-text-small"
      htmlFor="notepad-minimize"
    >
      Untitled - Notepad
    </TabItem>
    <TabItem
      className="tab-explorer"
      labelClass="label-folder-explorer-small"
      htmlFor="explorer-minimize"
    >
      Exploring - Ms-dos_5 (C:)
    </TabItem> */}
  </menu>
);

const StartMenu = ({ onShutDown, onBooking }) => {
  const [showSeparator, setShowSeparator] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSeparator((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTimeWithFlashingSeparator = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}${showSeparator ? ":" : " "}${minutes}`;
  };

  return (
    <nav id="start-menu" className="start-menu">
      <label className="label-start" htmlFor="start" tabIndex={0}>
        Start
      </label>
      <div className="menu-container">
        <MainMenu onShutDown={onShutDown} onBooking={onBooking} />
      </div>
      <Tabs />
      <a href="https://www.instagram.com/miethamusic/" target="_blank">
        <label className="icon icon-instagram" />
      </a>
      <a href="https://www.tiktok.com/@miethamusic" target="_blank">
        <label className="icon icon-tiktok" />
      </a>
      <div className="time">
        <time className="clock">{formatTimeWithFlashingSeparator()}</time>
      </div>
    </nav>
  );
};

export default StartMenu;
