import { useState, useEffect } from "react";

const useLetterTransition = (text, interval = 30) => {
  const [displayedText, setDisplayedText] = useState(" ");

  useEffect(() => {
    setDisplayedText(" ");

    if (!text) return;

    const timer = setInterval(() => {
      setDisplayedText((current) => {
        const nextIndex = current === " " ? 0 : current.length;
        return nextIndex < text.length
          ? current === " "
            ? text[0]
            : current + text[nextIndex]
          : current;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [text, interval]);

  return displayedText;
};

export default useLetterTransition;
