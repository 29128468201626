import { useState } from "react";
import useSound from "use-sound";
import clickSound from "./assets/sounds/switch-on.mp3";
import { Alert } from "./components/desktop/alert";
import BlueScreenError from "./components/desktop/BlueScreen";
import Icon from "./components/desktop/icon/Icon";
import StartMenu from "./components/desktop/StartMenu";
import { Window } from "./components/desktop/window/Window";
// import { GamesWindow } from "./components/GamesWindow";
import MusicPlaylist from "./components/custom/MusicPlaylist";
import { FileList } from "./components/desktop/window/FileList";
import Ticker from "./components/ticker/Ticker";
import useUrlCheck from "./hooks/useUrlCheck";
import dmwk from "./styles/dmwk.module.scss";

const WELCOME = "welcome";
const MUSIC_PLAYER = "music_player";
const SPOTIFY_PLAYER = "spotify_player";
const BOOKING = "booking";
const ABOUT = "about";
const GAMES = "games";
const VIDEOCLIPS = "videoclips";
const YT_PLAYER = "yt_player";

const CLIPS_LIST = [
  {
    name: "Góra dół",
    icon: "multimedia",
    url: "https://www.youtube.com/embed/7Dl7R0_h-SY",
  },
  {
    name: "Ostatni raz",
    icon: "multimedia",

    url: "https://www.youtube.com/embed/YzdlmezVH9Q",
  },
  {
    name: "Zanim pójdziesz",
    icon: "multimedia",

    url: "https://www.youtube.com/embed/7VmFJBdsMGQ",
  },
  {
    name: "Nieść Vibe",
    icon: "multimedia",

    url: "https://www.youtube.com/embed/H5c5bgqRGHU",
  },
];

export function App() {
  const [ytVideo, setYtVideo] = useState({ name: "", url: "" });

  const [windows, setWindows] = useState({
    [WELCOME]: {
      isOpen: true,
      zIndex: 1,
    },
    [MUSIC_PLAYER]: {
      isOpen: false,
      zIndex: 2,
    },
    [VIDEOCLIPS]: {
      isOpen: false,
      zIndex: 2,
    },
    [YT_PLAYER]: {
      isOpen: false,
      zIndex: 2,
    },
    [SPOTIFY_PLAYER]: {
      isOpen: false,
      zIndex: 2,
    },
    [GAMES]: {
      isOpen: false,
      zIndex: 2,
    },
    [BOOKING]: {
      isOpen: false,
      zIndex: 2,
    },
    [ABOUT]: {
      isOpen: false,
      zIndex: 2,
    },
  });

  const [showBlueScreen, setShowBlueScreen] = useState(false);
  const [playClick] = useSound(clickSound);

  useUrlCheck("/listen_trybsporth", () => {
    openWindow(MUSIC_PLAYER);
    // closeWindow(WELCOME);
  });

  const openWindow = (id, openWindow = true) => {
    playClick();
    setWindows((prevWindows) => {
      const maxZIndex = Math.max(
        ...Object.values(prevWindows).map((w) => w.zIndex)
      );
      return {
        ...prevWindows,
        [id]: { ...prevWindows[id], isOpen: openWindow, zIndex: maxZIndex + 1 },
      };
    });
  };

  const bringToFront = (id) => {
    setWindows((prevWindows) => {
      const maxZIndex = Math.max(
        ...Object.values(prevWindows).map((w) => w.zIndex)
      );
      return {
        ...prevWindows,
        [id]: { ...prevWindows[id], zIndex: maxZIndex + 1 },
      };
    });
  };

  const closeWindow = (id) => {
    playClick();
    setWindows((prevWindows) => ({
      ...prevWindows,
      [id]: { ...prevWindows[id], isOpen: false },
    }));
  };

  const renderWindow = (id) => {
    if (!windows[id]?.isOpen) return null;

    const commonProps = {
      show: true,
      style: { zIndex: windows[id].zIndex },
      onMouseDown: () => bringToFront(id),
      onClose: () => closeWindow(id),
    };

    const handleSelectVideo = (index) => {
      const { url, name } = CLIPS_LIST[index];
      setYtVideo({ name, url });
      openWindow(YT_PLAYER);
    };

    switch (id) {
      case MUSIC_PLAYER:
        return (
          <Window
            id={id}
            key={id}
            title="Tryb Sporth Player"
            width={336 * 2}
            height="auto"
            {...commonProps}
          >
            <MusicPlaylist />
          </Window>
        );

      case WELCOME:
        return (
          <Window
            key={id}
            width={"100vh"}
            height={"100vw"}
            title="DMWK Z MTH"
            {...commonProps}
          >
            <div className={dmwk.container}>
              <img
                className={dmwk.image}
                src="images/release-party-cover.jpg"
                alt="DMWK Z MTH"
              />
              <a
                href="https://goingapp.pl/wydarzenie/dmwk-z-mth-tryb-sporth-release-party/warszawa-listopad-2024"
                target="_blank"
                className={dmwk.ticketButton}
              >
                Kup bilet
              </a>
            </div>
          </Window>
          // <WindowWelcome key={id} title="Witamy" {...commonProps}>
          //   Hello ty, witaj na sajcie zespołu Miętha...
          //   <br />
          //   <br />
          //   Poczuj się AWGSem w akcji.
          //   <br />
          //   Łapska na klawiaturkę i jedziemy.
          //   <br />
          //   <br />
          //   <DrumKit />
          // </WindowWelcome>
        );

      case VIDEOCLIPS:
        return (
          <Window
            id={id}
            key={id}
            width={200}
            height={400}
            title="Klipy"
            {...commonProps}
          >
            <FileList files={CLIPS_LIST} onClick={handleSelectVideo} />
          </Window>
        );

      case YT_PLAYER:
        return (
          <Window
            id={id}
            key={id}
            width={"100vw"}
            height={"calc(100vw/1.8)"}
            title={ytVideo.name}
            {...commonProps}
          >
            <iframe
              width="100%"
              height="100%"
              src={ytVideo.url}
              title={ytVideo.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Window>
        );

      case SPOTIFY_PLAYER:
        return (
          <Window id={id} key={id} title="Miętha Mix" {...commonProps}>
            <iframe
              style={{
                borderRadius: 0,
                backgroundColor: "rgba(158, 15, 0, 255)",
              }}
              src="https://open.spotify.com/embed/playlist/37i9dQZF1EIWr3sUkLKQso?utm_source=generator&theme=0"
              width="100%"
              height="431"
              frameBorder="0"
              allowfullscreen
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </Window>
        );

      case GAMES:
        return <GamesWindow key={id} title="Gierki" {...commonProps} />;

      case ABOUT:
        return (
          <Window id={id} key={id} title="O nas" {...commonProps}>
            <p>
              Miętha to polski duet muzyczny, który tworzą raper Skip i
              producent AWGS. Miętha wyróżnia się na tle polskiej sceny
              muzycznej swoją umiejętnością tworzenia utworów, które są
              jednocześnie relaksujące i emocjonalne. Potrafią uchwycić intymne
              momenty i uczucia, co sprawia, że ich muzyka trafia do słuchaczy
              na głębokim poziomie. Inspirują się takimi gatunkami jak lo-fi
              hip-hop, soulowe ballady i współczesna elektronika. Miętha
              wyróżnia się introspekcyjnymi tekstami, luźnym flow i
              charakterystycznym vibe’em, który przyciąga zarówno fanów rapu,
              jak i alternatywy.
            </p>
            <p>
              Ich debiutancki album, "Audioportret", ukazał się w 2019 roku i od
              razu zyskał uznanie w środowisku muzycznym. Kolejne projekty,
              takie jak "36,6" czy "Szum", ugruntowały ich pozycję na polskiej
              scenie muzycznej. Mają talent do tworzenia głębokich,
              emocjonalnych utworów, które są zarazem chwytliwe i klimatyczne.
            </p>
          </Window>
        );

      case BOOKING:
        return (
          <Alert key={id} title="Booking" {...commonProps}>
            <p>
              Booking:{" "}
              <a href="mailto:klaudia@soldoutagencja.pl">
                klaudia@soldoutagencja.pl
              </a>
            </p>
            <p>+48 698 799 895</p>
          </Alert>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <input hidden data-start id="start" type="checkbox" />

      <main>
        <div id="desktop">
          <div id="icons">
            <Icon
              label="Tryb Sporth"
              iconClass="label-tryb-sporth"
              onClick={() => openWindow(MUSIC_PLAYER)}
            />
            <Icon
              label="Klipy"
              iconClass="label-multimedia"
              onClick={() => openWindow(VIDEOCLIPS)}
            />
            <Icon
              label="Miętha Mix"
              iconClass="label-cd"
              onClick={() => openWindow(SPOTIFY_PLAYER)}
            />
            <Icon
              label="O nas"
              iconClass="label-skip-awgs-x2"
              onClick={() => openWindow(ABOUT)}
            />
            {/* <Icon
              label="Pitu Pitu"
              iconClass="label-wordpad"
              style={{
                display: "none",
              }}
            /> */}
            {/* <Icon
              label="Gierki"
              iconClass="label-joystick"
              onClick={() => openWindow(GAMES)}
              style={{
                opacity: 0.25,
                userSelect: "none",
              }}
            /> */}
          </div>

          <div id="windows">{Object.keys(windows).map(renderWindow)}</div>
        </div>

        {showBlueScreen && (
          <div id="alerts">
            <BlueScreenError onClick={() => setShowBlueScreen(false)} />
          </div>
        )}

        <StartMenu
          onShutDown={() => setShowBlueScreen(true)}
          onBooking={() => openWindow(BOOKING)}
        />

        <div style={{ position: "relative", zIndex: 10000 }}>
          <Ticker>
            WPADNIJ DO NAS NA DOMÓWKĘ Z MTH 🍃🍃🍃 28.11 WOZOWNIA 🍃🍃🍃{" "}
          </Ticker>
        </div>
      </main>
    </>
  );
}
