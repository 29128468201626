.Ticker {
  position: fixed;
  width: 100%;
  height: 40px;
  bottom: 40px;
  padding: 10px 0;
  font-size: 12px;
  white-space: nowrap;
  color: #fff;
  background-color: #000;

  .segment {
    display: inline-block;
    padding-left: 25%;
    animation: scroll 5s infinite linear;
    font-family: Menlo, monospace;
    letter-spacing: 1;
    font-weight: normal;
  }

  &.reverse {
    .segment {
      animation-direction: reverse;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
