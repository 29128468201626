import { useEffect, useRef } from "react";

const useUrlCheck = (urlPath, action) => {
  // Keep track of URLs where the action has been called
  const actionCalledForUrls = useRef(new Set());

  useEffect(() => {
    const handleUrlChange = () => {
      const currentPath = window.location.pathname;

      if (
        currentPath === urlPath &&
        !actionCalledForUrls.current.has(currentPath)
      ) {
        action();
        actionCalledForUrls.current.add(currentPath);
      }
    };

    handleUrlChange();

    // Add listener for URL changes
    window.addEventListener("popstate", handleUrlChange);
    window.addEventListener("hashchange", handleUrlChange);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener("popstate", handleUrlChange);
      window.removeEventListener("hashchange", handleUrlChange);
    };
  }, [urlPath, action]);

  // Optional: Reset the Set when urlPath changes
  useEffect(() => {
    actionCalledForUrls.current.clear();
  }, [urlPath]);
};

export default useUrlCheck;
