button {
  cursor: pointer;
}

.audio {
  width: 100%;
}

.icon {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 22px;
  height: 20px;
  position: relative;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    color: #3d5544;
  }
}

.container {
  z-index: 10000;
  width: 100%;
  height: 100%;
}

.playlistWrapper {
  position: relative;
}

.bg {
  width: 100%;
  height: 100%;
}

.playlistContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.group {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
}

.title {
  font-weight: 600;
  font-family: Menlo, monospace;
  padding-top: 8px;
  padding-bottom: 4px;
  color: #3d5544;
  text {
    font-family: Menlo, monospace;
  }
}

.record {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 7%);
  display: flex;
  justify-content: flex-end;
  margin-top: 7%;
  margin-right: 7%;
  img {
    max-width: 12%;
    max-height: 12%;
    object-fit: contain;
  }
}

.controlContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  gap: 2px;
  width: 45%;
}

.controlButton {
  font-family: Menlo, monospace;
  border: 1.5px solid #3d5544;
  border-radius: 50px;
  text-align: center;
  background-color: transparent;
  color: #3d5544;
  text-transform: uppercase;
  cursor: pointer;
}

.flash {
  animation: flash 0.5s infinite;
}

@keyframes flash {
  0%,
  100% {
    border-color: rgba(61, 85, 68, 1);
  }
  50% {
    border-color: rgba(61, 85, 68, 0);
  }
}
