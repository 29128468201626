export const FileList = ({ files, onClick }) => {
  return (
    <menu className="list">
      {files?.map(({ name, icon }, index) => (
        <li key={index}>
          <label
            onClick={() => onClick(index)}
            className={`label-${icon}-small`}
          >
            {name}
          </label>
        </li>
      ))}
    </menu>
  );
};
